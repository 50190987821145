import React, { useRef, useEffect, useState } from "react";
import MenuNav from "src/components/global/menuNav/MenuNav.js";

import styled, { keyframes } from "styled-components";

import { Helmet } from "react-helmet";
import fbImg from "src/assets/images/img/fb.jpg";
import favicon from "src/assets/images/svg/favicon.svg";

const Wrapper = styled.div`
  padding-top: 0rem;
`;

const Upmenu = styled.div``;

// markup
const Menu = () => {
  useEffect(() => {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      window.upmenuSettings = {
        id: "ab5ec541-7221-11ec-9695-525400080621",
      };
    }

    const script = document.createElement("script");

    script.src = "https://cdn.upmenu.com/media/upmenu-widget.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, ["https://cdn.upmenu.com/media/upmenu-widget.js"]);
  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "pl" }}
        titleTemplate={`Pink Vegan – restauracja wegańska`}
        title={`Pink Vegan – restauracja wegańska`}
      >
        <link rel="icon" type="image/svg+xml" href={favicon} />
        <meta
          name="description"
          content="Pełna smaku i zdrowa kuchnia wegańska.
          Odkryj na nowo wegańskie smaki"
        />
        <meta name="image" content="../static/fb.jpeg" />
        <meta property="og:image" content={fbImg} />
        <meta property="og:image:secure_url" content={fbImg} />
        <meta property="og:url" content="http://pinkvegan.pl/" />
        <meta property="og:title" content="Pink Vegan" />

        <meta property="og:description" content="restauracja wegańska" />
      </Helmet>
      <MenuNav />

      <Wrapper>
        <Upmenu id="upmenu-widget"></Upmenu>
      </Wrapper>
    </>
  );
};

export default Menu;
