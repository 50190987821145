import React from "react";

import styled from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery";
import { Link } from "gatsby";

import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import mianInfo from "src/assets/data/mainInfo.js";
import fb from "src/assets/images/svg/fb_green.svg";
import instagram from "src/assets/images/svg/insa_green.svg";

import LogoAni from "src/assets/images/svg/leaf-nav-logo.inline.svg";

const Wrapper = styled.nav`
  padding: 0rem ${distances.gap}rem;
  background-color: ${colors.light};
  width: calc(100% - ${distances.gap * 2}rem);
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
  height: 80rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(-100%);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  visibility: hidden;
  pointer-events: none;
  /* &.scrolled { */
  pointer-events: auto;
  visibility: visible;
  transform: translateY(0rem);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  /* } */
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }

  .logo {
    align-self: flex-end;
    width: 77rem;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
`;

const SocialWrapper = styled.div`
  display: flex;
`;

const SocialLink = styled.a`
  margin-left: ${distances.gap / 2}rem;
`;

const Logo = styled.img`
  align-self: flex-end;
`;

const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
  margin-right: 20rem;

  @media (max-width: ${mediaQuery.desktop}) {
    /* margin-top: 20rem; */
  }
`;

const NavLink = styled(Link)`
  font-size: ${typographySizes.s}rem;
  color: ${colors.green};
  font-weight: 400;
  font-family: "sofia-pro", sans-serif;
  margin-left: ${distances.gap}rem;
  cursor: pointer;
  position: relative;
  @media (max-width: ${mediaQuery.desktop}) {
    :first-of-type {
      margin-left: 0;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
    padding: 20rem 0;
    font-size: ${typographySizes.m}rem;
  }
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &:hover {
    transform: translateY(-5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  ::before {
    content: "";
    position: absolute;
    bottom: -4rem;
    left: 0;
    width: 0%;
    height: 1rem;
    background-color: ${colors.green};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateY(0rem);
  }
  &.active {
    ::before {
      width: 100%;
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const NavLinkMenu = styled(Link)`
  font-size: ${typographySizes.s}rem;
  color: ${colors.pink};
  font-weight: 400;
  font-family: "sofia-pro", sans-serif;
  margin-left: ${distances.gap}rem;
  position: relative;
  cursor: pointer;
  ::before {
    content: "";
    position: absolute;
    bottom: -4rem;
    left: 0;
    width: 100%;
    height: 1rem;
    background-color: ${colors.pink};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateY(0rem);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
    font-size: ${typographySizes.m}rem;
    display: inline-block;
    flex-grow: 0;

    padding: 20rem 0;
    ::before {
      bottom: 16rem;
    }
  }
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &:hover {
    transform: translateY(-5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    ::before {
      bottom: -9rem;
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const MenuNav = ({ scrolled }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]');
  }
  return (
    <Wrapper className={scrolled && "scrolled"}>
      {/* <Logo src={logo} /> */}
      <Link to="/">
        <LogoAni className="logo" />
      </Link>

      <LinksWrapper>
        <NavLinks>
          <NavLink
            to="/#onas"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            o nas
          </NavLink>
          <NavLink
            to="/#promocje"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            promocje
          </NavLink>
          <NavLink
            to="/#dostawa"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            dostawa
          </NavLink>
          <NavLink
            to="/#kontakt"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            kontakt
          </NavLink>
          {/* <NavLinkMenu to="/menu">menu</NavLinkMenu> */}
        </NavLinks>

        <SocialWrapper>
          <SocialLink href={mianInfo.facebook} target="_blank">
            <img src={fb} alt="facebook icon" />
          </SocialLink>
          <SocialLink href={mianInfo.instagram} target="_blank">
            <img src={instagram} alt="facebook icon" />
          </SocialLink>
        </SocialWrapper>
      </LinksWrapper>
    </Wrapper>
  );
};

export default MenuNav;
